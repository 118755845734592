// https://material-ui.com/customization/default-theme/#default-theme
import React from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginRight: 0,
    marginLeft: 0,

    [theme.breakpoints.up("xs")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "25%",
      paddingRight: "25%",
    },
  },

  rightOnly: {
    paddingLeft: 0,
    paddingRight: "16px",

    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      paddingRight: "80px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: "25%",
    },
  },

  leftOnly: {
    paddingLeft: "16px",
    paddingRight: 0,

    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "80px",
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "25%",
      paddingRight: 0,
    },
  },
}));

interface ContainerBoxProps {
  children: React.ReactChild | React.ReactFragment;
  rightOnly?: boolean;
  leftOnly?: boolean;
  className?: string;
}

function ContainerBox ({ children, rightOnly, leftOnly, className }: ContainerBoxProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        className,
        classes.root,
        { [classes.leftOnly]: leftOnly },
        { [classes.rightOnly]: rightOnly }
      )}
    >
      {children}
    </div>
  );
};

export default ContainerBox;
